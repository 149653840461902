import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IconButton } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import "./Editor.css";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { IssueState } from "../Dashboard";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  borderRadius: "20px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal() {
  const [open, setOpen] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [title, setTitle] = React.useState("");
  const { getIssues } = React.useContext(IssueState);
  // const {user, setUser} = React.useContext(Appstate);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDesChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const authType = localStorage.getItem("authType");
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("isloggedIn");

      var config;
      if (authType !== "google") {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
        config = {};
      }
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}issue`,
        { title, description, authType, userId },
        config
      );
      toast.success("Query Posted Successfully!", {
        style: { backgroundColor: "black", color: "white" },
        position: "top-left",
      });
      getIssues();
      setTitle("");
      setDescription("");
    } catch (e) {
      toast.error("Error Posting Query!", {
        style: { backgroundColor: "red", color: "black" },
      });
    }
    handleClose();
  };
  return (
    <div>
      <Toaster />
      <IconButton className="icon-btn" onClick={handleOpen}>
        <Add />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p></p>
              <h1 style={{ fontWeight: 500, margin: "unset" }}>
                Add your Query
              </h1>
              <IconButton
                sx={{
                  backgroundColor: "#efefef",
                  width: "35px",
                  height: "35px",
                }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </div>
            <div style={{ margin: "20px 0px", marginBottom: "12px" }}>
              <h3 style={{ fontWeight: "normal" }}>Add a Title</h3>
              <TextField
                label="Title"
                fullWidth
                value={title}
                onChange={handleTitleChange}
              />
            </div>
            <h3 style={{ fontWeight: "normal" }}>Add Description</h3>
            <div
              style={{
                paddingBottom: "10px ",
                marginBottom: "10px",
              }}
            >
              <div>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleDesChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <input
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                />
                <label htmlFor="upload-file">
                  <Button
                    variant="text"
                    component="span"
                    style={{ color: "black", textTransform: "none" }}
                  >
                    <ImageIcon /> Paste, drop or click to add files
                  </Button>
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={title !== "" && description !== "" ? false : true}
              >
                Post Query
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
