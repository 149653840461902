import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import { Box, Divider, Menu, useMediaQuery } from "@mui/material";
import "./Query.css";
import { formatDistanceToNow } from 'date-fns';
import CustomizedTimeline from "./Timeline";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import { EmojiEmotionsOutlined } from "@mui/icons-material";
export default function Comments({comment}) {
  const time = comment.createdAt;
  const formattedTime = formatDistanceToNow(new Date(time), { addSuffix: true,threshold: 7 });
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const isSmallScreen13 = useMediaQuery('(max-width:1350px)');
  const isSmallScreen10 = useMediaQuery('(max-width:1000px)');
  return (<>
  
    <Box sx={{display:'flex',justifyContent:'flex-start',margin:'0 0',marginLeft:isSmallScreen10?'2vw':isSmallScreen13?'4vw':'10vw',alignItems:'center',}}><Box ><CustomizedTimeline name={comment.username} time={formattedTime}/></Box><Typography display={'flex'} alignItems={'center'} fontSize={'12px'}><Avatar sx={{width:'25px', height:'25px',mr:'2px'}}/> <b style={{marginRight:'4px'}}>{comment&&comment.username?comment.username:"Anonymous"}</b> added this {formattedTime}</Typography></Box>
    
    {/* <Box display={'flex'} flexDirection={'column'} marginRight={0} width={isSmallScreen ? '100%' : '65%'}> */}
            <Box display={'flex'} marginX={'0px'} sx={{marginLeft:!isSmallScreen13&&'7%'}}>
              {!isSmallScreen &&
              <>
                <Avatar style={{ marginTop: '0px', marginLeft: !isSmallScreen13?'5vw':'2vw',marginTop:'2px' ,width:'40px',height:'40px'}}  aria-label="recipe"/>
                <Box display={'flex'} flexDirection={'column'} marginTop={'15px'} marginRight={'-2.2px'}>
                <Divider sx={{ width: '11px', height: '0.2px', backgroundColor: '#d0d7de', marginX: '0',transform:'rotate(135deg)' }}/>
                <Divider sx={{ width: '11px', height: '0.2px', backgroundColor: '#d0d7de',marginTop:'6px', marginX: '0',transform:'rotate(45deg)' }}/>
                </Box>
              </>
              }
              <Card sx={{border: '0.3px solid #d0d7de',color:'#1f2328',boxShadow:'none', minWidth: isSmallScreen? '94%':"81%",maxWidth:isSmallScreen? '85%':"90%", margin: isSmallScreen ? "0px auto" : '0px 0px', marginRight: isSmallScreen ? '' : '5vw',borderRadius:'6px' }}>
                <Box display={"flex"} sx={{borderBottom: '0.3px solid #d0d7de'}}>
                  <CardHeader
                    sx={{ backgroundColor: "#f6f8fa",fontFamily:'Seuge Ui', display:'flex',borderLeft:'none',borderLeftStyle:'outset',alignItems:'center', borderLeft: '0px', color: "black", height: "2px", width: '100%' }}
                    title={comment && 
                      (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '13px',color:'grey',fontWeight:'lighter' }}>
                        <b style={{  marginRight: '4px',color:'black'}}>{comment.username?comment.username:"Anonymous"}</b>{" Commented " + formattedTime}
                        </span>
                        {/* <p style={{fontWeight:'lighter',color:'gray'}}>{<MoreHorizSharpIcon fontSize="10"/>}</p> */}
                      </div>)}
                  />
                </Box>
                <Divider/>
                <CardContent sx={{ backgroundColor: 'white',padding:'16px',overflow:'visible', }}>
                  {comment && <>
                    {/* <h1>{comment.title}</h1> */}
                    <Typography variant="body2" color={'text.primary'} fontWeight={'lighter'}  dangerouslySetInnerHTML={{ __html: comment.text }}/>
                  </>}
                  <EmojiEmotionsOutlined  style={{borderRadius:'100%',height:'20px',width:'20px', backgroundColor:'#f6f8fa',color:'gray',padding:'2px',border:'0.2px solid #d0d7de'}}/>
                </CardContent>
              </Card>
            {/* </Box> */}
            </Box>
        </>
  )
}
