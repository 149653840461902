import React from "react";
import { Card, CardMedia } from "@mui/material";

export default function ChatboxCard() {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
        margin: "20px auto",
        borderRadius: "20px",
        maxWidth: "90%",
      }}
    >
      <CardMedia
        component="img"
        height="80%"
        image={
          "https://png.pngtree.com/thumb_back/fh260/background/20210902/pngtree-blue-simple-clay-dialog-blue-chat-box-image_785256.jpg"
        }
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        alt="Image"
      />
    </Card>
  );
}
