import * as React from 'react';
import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import clsx from 'clsx';
import axios from 'axios';
import {toast,Toaster} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../../Footer';
export default function CorporatePlan() {
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = async() => {
    try{
      const url = process.env.REACT_APP_BASE_URL
      const {data} = await axios.post(`${url}users/details`,formData);
      console.log(data);
      toast.success("Sent Details Successfully!");
      alert("Sent Details Successfully!");
      navigate("/");
    }catch(e){
      console.log(e.message);
      toast.error("Error Sending Details, try again later!")
    }
  };
  const isFormValid = () => {
    // Check if all required fields have values
    return (
      formData.firstName !== '' &&
      formData.phone !== '' &&
      formData.email !== '' &&
      formData.company !== ''
    );
  };
  const issmallscreen = useMediaQuery('(max-width:1000px)');
  const issmallscreen4 = useMediaQuery('(max-width:400px)');
  return (
    <>
    <Navbar/>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '91vh',flexDirection:'column',margin:issmallscreen?'4vh 0':'3vh 0' }}>
      <Toaster/>
      <Box sx={{height:'auto',backgroundColor:'white',backgroundImage:'url("https://c4.wallpaperflare.com/wallpaper/871/1013/108/minimalism-red-abstract-digital-art-wallpaper-preview.jpg")',color:'white',borderRadius:'20px',display:'flex',alignItems:'center',flexDirection:'column',padding:issmallscreen4?'20px 20vw':'25px 20px', width:issmallscreen4?'60%':'auto'}}>
        <Typography fontWeight={'bold'} fontSize={'28px'} marginBottom={'15px'} marginTop={'30px'}>Corporate Plan Details</Typography>
      
        <FormControl defaultValue="" required>
        <Label>First Name</Label>
        <StyledInput 
          sx={{backgroundColor:'#efefef',marginBottom:'6px',borderRadius:'20px'}} 
          placeholder="Write your first name here" 
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        {/* <HelperText /> */}
      </FormControl>
      <FormControl defaultValue="">
        <Label>Last Name</Label>
        <StyledInput 
        sx={{backgroundColor:'#efefef',marginBottom:'6px',borderRadius:'20px'}} 
          placeholder="Write your last name here" 
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
        {/* <HelperText /> */}
      </FormControl>
      <FormControl defaultValue="" required>
        <Label>Phone</Label>
        <StyledInput 
        type='number'
        sx={{backgroundColor:'#efefef',marginBottom:'6px',borderRadius:'20px'}} 
          placeholder="Write your phone number here" 
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        {/* <HelperText /> */}
      </FormControl>
      <FormControl defaultValue="" required>
        <Label>Email</Label>
        <StyledInput 
        sx={{backgroundColor:'#efefef',marginBottom:'6px',borderRadius:'20px'}} 
          placeholder="Write your email here" 
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {/* <HelperText /> */}
      </FormControl>
      <FormControl defaultValue="" required>
        <Label>Company</Label>
        <StyledInput 
        sx={{backgroundColor:'#efefef',marginBottom:'6px',borderRadius:'20px'}} 
          placeholder="Write your company name here" 
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
        {/* <HelperText /> */}
      </FormControl>
      <Button variant="contained" sx={{backgroundColor:'#378f7b',color:'white',marginTop:'50px',textTransform:'none'}} onClick={handleSubmit} disabled={!isFormValid()}>Send Us</Button>
      </Box>
    </div>
    <Footer/>
    </>
  );
}
const StyledInput = styled(Input)(
  ({ theme }) => `
  .${inputClasses.input} {
    width: ${useMediaQuery('(max-width:400px)')?'240px':'300px'};
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  }
`,
);

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
      {children}
      {required ? ' *' : ''}
    </p>
  );
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;



const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
