import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Menu } from "@mui/icons-material";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const userDataString = localStorage.getItem("userData");
  const userDataObject = JSON.parse(userDataString);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const history = useNavigate();
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {userDataObject &&
          (userDataObject.role === "admin" ||
            userDataObject.role === "subAdmin") && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => history("/users")}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </ListItem>
          )}
        <ListItem disablePadding>
          <ListItemButton onClick={() => history("/plans")}>
            <ListItemIcon>
              <MiscellaneousServicesIcon />
            </ListItemIcon>
            <ListItemText primary="Our Plans" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Button
        variant="contained"
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#efefef",
          color: "black",
          paddingRight: "0px",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "12px",
          minWidth: 0,
          marginRight: "8px",
        }}
        startIcon={<Menu />}
        onClick={toggleDrawer(true)}
      />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
