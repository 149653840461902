import { createContext, useState } from "react";
import "./Dashboard.css";
import ChatBoxCard from "./sections/ChatBoxCard";
import Header from "./sections/Header";
import ImageSliderCard from "./sections/ImageSliderCard";
import Navbar from "./sections/Navbar";
import StaticCard from "./sections/StaticCard";
import axios from "axios";
import Footer from "./Footer";
const IssueState = createContext();
export default function Dashboard() {
  const [queries, setQueries] = useState([]);
  const authType = localStorage.getItem("authType");
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("isloggedIn");

  const getIssues = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}issue/get`;
      var config;
      if (authType !== "google") {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } else {
        config = {};
      }
        const response = await axios.post(url, { authType, userId}, config);
        setQueries(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <IssueState.Provider value={{ queries, setQueries, getIssues }}>
      <Navbar />
      <div style={{ backgroundColor: "white" }}>
        <h1
          style={{
            display: "flex",
            marginY: "10px ",
            justifyContent: "center",
          }}
        >
          Discord Telegram, Hike
        </h1>
        {token && <Header />}
        <ImageSliderCard />
        <StaticCard />
        <ChatBoxCard />
        <Footer />
      </div>
    </IssueState.Provider>
  );
}
export { IssueState };
