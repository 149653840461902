import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RemoveShoppingCartOutlined } from '@mui/icons-material';
import axios from 'axios';
import { Appstate } from '../../../App';
export default function OutlinedCard({name,amount}) {
    const navigate = useNavigate();
    const {setCart} = React.useContext(Appstate);
    const paymentHandler=async() => {
      const userId = localStorage.getItem("userId");
      const { data: {key} } = await axios.get(` ${process.env.REACT_APP_BASE_URL}getkey`);
      const { data: {order} } = await axios.post(` ${process.env.REACT_APP_BASE_URL}payment`,{amount,Plan:[name],user:userId});
        console.log(order)
        const option = {
            key: key, // Enter the Key ID generated from the Dashboard
            amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "React Helper",
            description: `React Helper ${name} plan`,
            image: "",
            order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            callback_url:  `${process.env.REACT_APP_BASE_URL}payment/paymentverification`,
            prefill: {
                name: "Kiran",
                email: "gaurav.kumar@example.com",
                contact: "9000090000"
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#a451d9"
            } 
        };
        if (!window.confirm(`You are starting payment for ${name} Plan`)) return;
        const razor = new window.Razorpay(option);
            razor.open();
            razor.on("success",()=>{alert("Payment Success!")})
            razor.on("failed",()=>{alert("Failed!")})
        }
        const handleRemoveFromCart = () => {
            setCart(previous => previous.filter(item => item.name !== name));
          };
  return (
    <Box sx={{ minWidth: 275,backgroundColor:'black',color:'#efefef',borderRadius:'20px' }}>
      <Card variant="outlined" sx={{backgroundColor:'black',color:'#efefef',borderRadius:'20px'}}><CardContent>
      <Box display='flex' marginBottom={'20px'} marginTop={'20px'}alignItems={'center'} justifyContent={'center'}><Avatar sx={{ backgroundColor: 'skyblue', color: 'black' }}>RH</Avatar></Box>
      <Typography sx={{display:'flex',justifyContent:'center'}} variant="h5" component="div" >
      {name} Plan
      </Typography>
      <Box display='flex' flexDirection={'row'} margin={'15px 0px'} alignItems={'center'} justifyContent={'center'}><Typography sx={{ color: '#efefee', display: 'flex', alignItems: 'center' }}>₹{amount} <Typography fontSize={'11px'} color={'grey'}>{" "}+gst /user</Typography></Typography></Box>
    </CardContent>
    <Box display={'flex'} justifyContent={'center'} sx={{ marginBottom: '12px' }}><Chip style={{boxShadow:'3px 3px 5px red'}} icon={<RemoveShoppingCartOutlined color='#efefee'/>} variant='outlined'
            sx={{ color: '#efefee', padding: 'auto',cursor:'pointer',height:'40px',width:'220px' }} 
            label="Remove from Cart " onClick={handleRemoveFromCart}
          /></Box>
    <CardActions sx={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'20px'}}>
      <Chip variant='outlined'label="More Details" sx={{color:'#efefee',width:'110px',height:'35px',boxShadow:'2px 2px 4px purple'}}  onClick={()=>{navigate("/plans")}}/>
      <Chip variant='outlined'label="Buy Plan" sx={{color:'#efefee',width:'100px',height:'35px',boxShadow:'2px 2px 4px green'}} onClick={paymentHandler}/>
    </CardActions></Card>
    </Box>
  );
}