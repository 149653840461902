import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "white",
        color: "black",
        padding: "34px",
        paddingTop: "20px",
        marginTop: "40px",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"10px"}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Avatar
            sx={{
              width: "50px",
              height: "50px",
              backgroundColor: "#d38ef2",
              color: "black",
            }}
          >
            RH
          </Avatar>
          <Typography
            display={"flex"}
            alignItems={"center"}
            fontSize={"26px"}
            ml={1}
            fontFamily={"cursive"}
          >
            React Helper
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Link to="/contact_us" style={{ textDecoration: "none" }}>
            Contact Us
          </Link>
        </Grid>
        <Grid item>
          <Link to="/cancellation&refund" style={{ textDecoration: "none" }}>
            Cancellation and Refund Policy
          </Link>
        </Grid>
        <Grid item>
          <Link to="/privacy" style={{ textDecoration: "none" }}>
            Privacy Policy
          </Link>
        </Grid>
        <Grid item>
          <Link to="/terms&conditions" style={{ textDecoration: "none" }}>
            Terms and Conditions
          </Link>
        </Grid>
        <Grid item>
          <Link to="/shipping" style={{ textDecoration: "none" }}>
            Shipping and Delivery Policy
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" align="center" sx={{ marginTop: "16px" }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          React Helper &copy; {new Date().getFullYear()}
        </Link>
      </Typography>
    </footer>
  );
};

export default Footer;
