import React, { useContext, useEffect } from "react";
import { Card, CardContent, Typography, Divider, Tooltip } from "@mui/material";
import "../Dashboard.css";
import TransitionsModal from "./TransitionsModal";
import { useNavigate } from "react-router-dom";
import { IssueState } from "../Dashboard";
import { Appstate } from "../../../App";

export default function StaticCard() {
  const navigate = useNavigate();
  const { queries, getIssues } = useContext(IssueState);
  const { setQueryNum } = useContext(Appstate);
  useEffect(() => {
    getIssues();
  }, []);

  return (
    <Card
      sx={{
        width: "90%",
        margin: "20px auto",
        borderRadius: "20px",
        maxWidth: "90%",
      }}
    >
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
          Query List
        </Typography>
        <TransitionsModal />
      </CardContent>
      <Divider />
      <CardContent style={{ maxHeight: "265px", overflowY: "auto" }}>
        {queries.map((issue, i) => (
          <div
            key={i}
            className="container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setQueryNum(i + 1);
              navigate(`/queries/${issue._id}`);
            }}
          >
            <Tooltip title="Click to view full query" arrow>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  marginTop: "10px",
                  padding: "5px",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {issue.title}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", padding: "5px", color: "white" }}
            >
              #{i + 1} added by {issue.name ? issue.name : "Unknown"}
            </Typography>

            <Divider sx={{ margin: "10px 0" }} />
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
