import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../../Footer';

const containerStyle = {
  paddingTop: '16px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  textAlign:'justify'
};

const sectionStyle = {
  marginBottom: '24px',
};

const paragraphStyle = {
  marginBottom: '8px',
};

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="md" style={containerStyle}>
        <section style={sectionStyle}>
          <Typography variant="h4" style={{ marginBottom: '16px' }}>Privacy Policy</Typography>
          <Typography variant="body1" style={paragraphStyle}>Last updated on Feb 27th 2024</Typography>
        </section>
        <section style={sectionStyle}>
          <Typography variant="body1" style={paragraphStyle}>
            This privacy policy sets out how USHA KIRAN RAJ BRUNDHAVANAM uses and protects any information that you give USHA KIRAN RAJ BRUNDHAVANAM when you use this website.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
          USHA KIRAN RAJ BRUNDHAVANAM is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            USHA KIRAN RAJ BRUNDHAVANAM may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            We may collect the following information:
          </Typography>
          <ul style={paragraphStyle}>
            <li>Name and job title</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences and interests</li>
            <li>Other information relevant to customer surveys and/or offers</li>
          </ul>
          <Typography variant="body1" style={paragraphStyle}>
            What we do with the information we gather:
          </Typography>
          <ul style={paragraphStyle}>
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests.</li>
          </ul>
          <Typography variant="body1" style={paragraphStyle}>
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in suitable measures.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            How we use cookies:
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            Controlling your personal information:
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            You may choose to restrict the collection or use of your personal information in the following ways:
          </Typography>
          <ul style={paragraphStyle}>
            <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</li>
            <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at kiranb9555@gmail.com.</li>
          </ul>
          <Typography variant="body1" style={paragraphStyle}>
            We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.
          </Typography>
          <Typography variant="body1" style={paragraphStyle}>
            Disclaimer: The above content is created at USHA KIRAN RAJ BRUNDHAVANAM's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
          </Typography>
        </section>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
