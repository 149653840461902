import React from 'react';
import Success from './Success';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
export default function PaySuccess() {
    const issmallscreen = useMediaQuery('(max-width:600px)');
    const isextrasmallscreen = useMediaQuery('(max-width:380px)');
    const navigate = useNavigate();
  return (
    <Box>
        <div style={{display:'flex', width:'100vw',height:'90vh',flexDirection:'column',alignItems:'center',justifyContent:'center'}}><Success/> <Typography fontFamily={'cursive'} fontSize={isextrasmallscreen?'28px':issmallscreen?'35px':'48px'}>
        Payment Successful!</Typography>
        <Typography fontSize={isextrasmallscreen?'20px':issmallscreen?'25px':'30px'}>You're welcomed in our family</Typography>
        <Typography fontSize={'20px'}>Thank You for Choosing us...</Typography>
        <Button variant="outlined" sx={{textTransform:'none',backgroundColor:'black',color:'white',height:'40px',width:'30%',marginTop:'40px',borderRadius:'10px',marginBottom:'8px'}} onClick={()=>{navigate("/")}} ><Typography style={{display:'flex',alignItems:'center'}}>Go Back to Dashboard <ReplyIcon sx={{marginLeft:'4px',marginBottom:'3px'}}/></Typography></Button>
        </div>
    </Box>
  )
}
