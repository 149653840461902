import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

export default function AfterGoogleLogin({setLogin,setUser}) {
  const { id } = useParams();
  const navigate = useNavigate();
  async function fetchUser(){
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}users/user`,{userId:id});
    setUser(response.data.user);
    if(!response.status === 200){
        toast.error("Error Authentcating!",{style:{backgroundColor:'red',color:'black'}});
        navigate("/login")
    }
    else{
        console.log(response.data);
        localStorage.setItem("userId", response.data.user._id);
        localStorage.setItem("isloggedIn",true);
        localStorage.setItem("authType","google");
        setLogin(true);
        navigate("/");
    }
  }
  useEffect(()=>{
    fetchUser();
  },[])
  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <div>
        <h1>Fetching Data from google...</h1>
        <br />
        <h3>Please wait...</h3>
      </div>
    </Box>
  );
}
