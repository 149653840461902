import { Box, Chip, Grid, Typography, useMediaQuery } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import CartCard from './CartCard'
import { Appstate } from '../../../App'
import Navbar from './Navbar';
import axios from 'axios';
import Footer from '../Footer';
export default function CartPage() {
  const {cart} = React.useContext(Appstate);
  const [totalAmount, setTotalAmount] = useState(0);
  const namesArray = cart.map(item => item.name);
    const calculateTotalAmount = () => {
        let total = 0;
        if (Array.isArray(cart)) {
            total = cart.reduce((acc, item) => acc + item.amount, 0);
        }
        setTotalAmount(total);
    }

    // Call the function to calculate total amount when component mounts
    useEffect(() => {
        calculateTotalAmount();
    }, [cart]);
  const paymentHandler=async() => {
    const { data: {key} } = await axios.get(` ${process.env.REACT_APP_BASE_URL}getkey`);
    const { data: {order} } = await axios.post(` ${process.env.REACT_APP_BASE_URL}payment`,{amount:totalAmount,Plan:namesArray});
      console.log(order)
      const option = {
          key: key, // Enter the Key ID generated from the Dashboard
          amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "React Helper",
          description: `React Helper Multiple plan`,
          image: "",
          order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url:  `${process.env.REACT_APP_BASE_URL}payment/paymentverification`,
          prefill: {
              name: "Kiran",
              email: "gaurav.kumar@example.com",
              contact: "9000090000"
          },
          notes: {
              address: "Razorpay Corporate Office"
          },
          theme: {
              color: "#a451d9"
          } 
      };
      if (!window.confirm(`You are starting payment for All cart Plans`)) return;
      const razor = new window.Razorpay(option);
          razor.open();
          razor.on("success",()=>{alert("Payment Success!")})
          razor.on("failed",()=>{alert("Failed!")})
      }
const issmallscreen = useMediaQuery('(max-width:1160px)');
  return (
    <>
    <Navbar/>
    <Box display={'flex'} alignItems={'center'} width={'100vw'} flexDirection={'column'} height={issmallscreen?'auto':'90vh'} justifyContent={'center'} paddingTop={issmallscreen?'7vh':'auto'} >
        <Grid width={issmallscreen?'90%':'80%'} borderRadius={'40px'} border='4px solid black' height={issmallscreen?'auto':'80%'} minHeight={'80vh'}  padding={issmallscreen?"20px  40px":'40px 20px'}  bgcolor={'white'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'} >
        <Box width={'100%'} display={'flex'} justifyContent={'center'} textAlign={'center'} ><Typography fontSize={'48px'} marginBottom={'15px'} fontWeight={'bold'}>{cart.length?`You have ${cart.length} ${cart.length>1?'Plans':'Plan'} in your cart`:"You have no plans in your cart"}</Typography></Box>
        {cart.map((P,i)=>{
            return <Grid key={i} marginRight={issmallscreen?'10px':'45px'} marginBottom={'20px'} item xs={12} sm={6} md={4} lg={3}>
            <CartCard name={P.name} amount={P.amount}/>
          </Grid>
        })}
        {cart.length?<Chip  variant='outlined' label='Buy All Plans' sx={{width:'50%',height:'40px',display:'flex',backgroundColor:'black',color:'white',cursor:'pointer'}} onClick={paymentHandler}/>:<></>}
        </Grid>
    </Box>
    <Footer/>
    </>
  )
}
