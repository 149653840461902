import React from "react";
import Chip from "@mui/material/Chip";
import { Avatar, Box, Button, useMediaQuery } from "@mui/material";
export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {isSmallScreen ? null : <Button></Button>}
      <Box
        direction="row"
        spacing={4}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        
        <Chip
          sx={{
            fontSize: "20px",
            paddingX: "4px",
            borderRadius: "50px",
            paddingY: "20px",
            marginX: "4px",
            marginY: "4px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              cursor: "pointer",
            },
          }}
          label="Home"
          variant="outlined"
        />
        <Chip
          sx={{
            fontSize: "20px",
            paddingX: "4px",
            borderRadius: "50px",
            paddingY: "20px",
            marginX: "4px",
            marginY: "4px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              cursor: "pointer",
            },
          }}
          label="Sales"
          variant="outlined"
        />
        <Chip
          sx={{
            fontSize: "20px",
            paddingX: "4px",
            borderRadius: "50px",
            paddingY: "20px",
            marginX: "4px",
            marginY: "4px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              cursor: "pointer",
            },
          }}
          label="Demo"
          variant="outlined"
        />
        <Chip
          sx={{
            fontSize: "20px",
            paddingX: "4px",
            paddingY: "20px",
            marginX: "4px",
            borderRadius: "50px",
            //   marginy: '4px',
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              cursor: "pointer",
            },
          }}
          label="React Helper"
          variant="outlined"
        />
      </Box>
      {isSmallScreen ? null : <Button></Button>}
      <Box sx={{ display: "none" }}>
        <Avatar
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          src=""
        />
      </Box>
    </Box>
  );
}
