// import React from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {  Google, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import {  toast } from "react-hot-toast";
import axios from "axios";
// import { GoogleLogin } from 'react-google-login';
export default function Login({  setLogin,setUser }) {
  const [error, setError] = useState(null);
  const [values, setValues] = useState({
    name: "",
    password: "",
    showPassword: false,
  });

  const issmallscreen6 = useMediaQuery("(max-width:500px)");
  const issmallscreen7 = useMediaQuery("(max-width:750px)");
  const issmallscreen9 = useMediaQuery("(max-width:900px)");
  const issmallscreen11 = useMediaQuery("(max-width:1100px)");
  const issmallscreen14 = useMediaQuery("(max-width:1400px)");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGoogleSignIn = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}users/auth/google`;
  };
  const navigate = useNavigate();
  const handleSubmit = async () => {
    // console.log(process.env.REACT_APP_BASE_URL + "users/login");
    const params = {
      email: values.name,
      password: values.password,
    };
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/login`,
        params
      );
      toast.success("Login Success", {
        style: { backgroundColor: "green", color: "white" },
        position: "bottom-left",
      });
      // console.log(data)
      localStorage.setItem("isloggedIn", data.token);
      localStorage.setItem("userId", data.user._id);
      localStorage.setItem("userData", JSON.stringify(data.user));

      setLogin(true);
      setUser(data.user);
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (e) {
      //   console.log("e", e);
      toast.error(e.response.data.message || "Something went wrong", {
        style: { backgroundColor: "red", color: "white" },
        position: "bottom-left",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor:'#efefee'
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: issmallscreen6
            ? "95%"
            : issmallscreen7
            ? "70%"
            : issmallscreen9
            ? "50%"
            : issmallscreen11
            ? "40%"
            : issmallscreen14
            ? "30%"
            : "25%",
          minHeight: "55%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto 16px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
              marginTop: "40px",
            }}
            fontWeight={"bold"}
            fontSize={"26px"}
            fontFamily={"sans-serif"}
          >
            Welcome back! Glad to see you, Again!
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ margin: "6px 0px", marginTop: "14px" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              sx={{ height: "50px", backgroundColor: "#efefef" }}
              value={values.name}
              onChange={handleChange("name")}
              label="Name"
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ margin: "6px 0px", marginBottom: "10px" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              sx={{
                height: "50px",
                backgroundColor: "#efefef",
                marginBottom: "8px",
              }}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <Typography fontSize={"11px"} marginLeft={"185px"}>
              forgot Password?
            </Typography>
          </FormControl>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              height: "40px",
              width: "100%",
              borderRadius: "10px",
              marginBottom: "8px",
              backgroundColor: "#1E232C",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            onClick={() => handleSubmit()}
          >
            <Typography>Login</Typography>
          </Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ alignItems: "center", margin: "10px 10px" }}
          >
            <Divider
              sx={{ width: "40%", height: "0.2px", backgroundColor: "grey" }}
            />
            <Typography
              variant="body1"
              fontSize={"12px"}
              align="center"
              sx={{ mx: 2, whiteSpace: "nowrap" }}
            >
              or Login/Signup with
            </Typography>
            <Divider
              sx={{ width: "40%", height: "0.2px", backgroundColor: "grey" }}
            />
          </Box>
          {error && <Typography color="error">{error}</Typography>}
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "Red",
              color: "white",
              textTransform: "none",
              height: "40px",
              width: "100%",
              borderRadius: "10px",
            }}
            onClick={handleGoogleSignIn}
          >
            <Google />
          </Button>
        </Box>

        <Box>
          <Typography
            fontSize={"14px"}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
              marginTop: "80px",
            }}
          >
            Don't have an Account?{" "}
            <Typography fontSize={"14px"} color={"blue"}>
              <Link to="/register">Register Now</Link>
            </Typography>{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
