import React from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../../Footer';

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>Contact Us</Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', mb: 4 }}>Last updated on Feb 27th 2024</Typography>
        <Typography fontSize={'22px'} display={'flex'} justifyContent={'center'} mb={'16px'}>You may contact us using the information below:</Typography>
        <Grid container spacing={4} minWidth={'100%'} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>Telephone No</Typography>
              <Typography variant="body1">+91-XXXXXXXXXX</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>E-Mail ID</Typography>
              <Typography variant="body1">kiranb9555@gmail.com</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>Merchant Legal entity name</Typography>
              <Typography variant="body1">B U KIRAN RAJ</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>Registered Address</Typography>
              <Typography variant="body1"> CRPF CAMPUS Rangareddy TELANGANA 500005</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>Operational Address</Typography>
              <Typography variant="body1"> CRPF CAMPUS BARKAS  Rangareddy TELANGANA 500005</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default ContactUs;
