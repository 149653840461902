import React, { useContext} from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {  RemoveShoppingCartOutlined } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { Appstate } from "../../App";
const PlanCard = ({ count, amount, disc, name, first, second, handlePlan }) => {
  const paymentHandler = async () => {
    const userId = localStorage.getItem("userId");
    const {
      data: { key },
    } = await axios.get(` ${process.env.REACT_APP_BASE_URL}getkey`);
    const {
      data: { order },
    } = await axios.post(` ${process.env.REACT_APP_BASE_URL}payment`, {
      amount,
      Plan: [name],
      user : userId
    });
    console.log(order);
    const option = {
      key: key, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "React Helper",
      description: `React Helper ${name} plan`,
      image: "",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: `${process.env.REACT_APP_BASE_URL}payment/paymentverification`,
      prefill: {
        name: "Kiran",
        email: "gaurav.kumar@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#a451d9",
      },
    };
    if (!window.confirm(`You are starting payment for ${name} Plan`)) return;
    const razor = new window.Razorpay(option);
    razor.open();
    razor.on("success", () => {
      alert("Payment Success!");
    });
    razor.on("failed", () => {
      alert("Failed!");
    });
  };
  const navigate = useNavigate();
  const { cart, setCart } = useContext(Appstate);
  const AddCart = () => {
    const newItem = { name, amount };
    setCart((previous) => [...previous, newItem]);
  };
  const handleRemoveFromCart = () => {
    setCart((previous) => previous.filter((item) => item.name !== name));
  };
  const isInCart = cart.some((item) => item.name === name);
  return (
    <Card variant="outlined" sx={{ borderRadius: "20px" }}>
      <Box
        sx={{
          backgroundColor: "black",
          color: "grey",
          borderRadius: "20px",
          
        }}
      >
        <CardContent sx={{ backgroundColor: "black", borderRadius: "20px" }}>
          <Box
            display="flex"
            marginBottom={"30px"}
            marginTop={"20px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Avatar sx={{ backgroundColor: "skyblue", color: "black" }}>
              RH
            </Avatar>
          </Box>
          <Box
            display="flex"
            margin={"15px 0px"}
            marginBottom={"30px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{
                backgroundColor: "black",
                color: "#efefee",
                padding: "4px 8px",
                borderRadius: "10px",
                fontFamily: "sans-serif",
                fontWeight: "bold",
                boxShadow: "4px 4px 6px grey",
              }}
            >
              -{disc}%
            </Typography>
          </Box>
          <Box
            display="flex"
            fle
            margin={"15px 0px"}
            marginBottom={"30px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              fontSize={"27px"}
              fontFamily={"sans-serif"}
              fontWeight={"bold"}
              sx={{ color: "#efefee" }}
            >
              {name} Plan
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            margin={"15px 0px"}
            marginBottom={"30px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ color: "#efefee", display: "flex", alignItems: "center" }}
            >
              ₹{amount}{" "}
              <Typography fontSize={"11px"} color={"grey"}>
                {" "}
                +gst /user
              </Typography>
            </Typography>
          </Box>
          <Divider
            sx={{ backgroundColor: "grey", marginBottom: "30px" }}
            marginBottom={"30px"}
          />
          <Box
            display={"flex"}
            justifyContent={"center"}
            color={"grey"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            margin={"20px 0px"}
            marginBottom={"30px"}
          >
            <Box display={"flex"} alignItems={"center"} marginBottom={"15px"}>
              <CheckCircleIcon sx={{ marginRight: "5px", color: "#efefef" }} />
              {first ? first : `Text support for react error Unlimited`}{" "}
            </Box>
            <Box display={"flex"} alignItems={"center"} marginBottom={"20px"}>
              <CheckCircleIcon sx={{ marginRight: "5px", color: "#efefef" }} />{" "}
              {second
                ? second
                : `Suport for additional feautures in react application - ${count}`}
            </Box>
          </Box>
          {isInCart ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ marginBottom: "12px" }}
            >
              <Chip
                style={{ boxShadow: "3px 3px 5px red" }}
                icon={<RemoveShoppingCartOutlined color="#efefee" />}
                variant="outlined"
                sx={{
                  color: "#efefee",
                  padding: "auto",
                  cursor: "pointer",
                  height: "40px",
                  width: "220px",
                }}
                label="Remove from Cart "
                onClick={
                  handlePlan
                    ? () => {
                        navigate("/corporate");
                      }
                    : handleRemoveFromCart
                }
              />
            </Box>
          ) : (
            !handlePlan && (
              <Box
                justifyContent={"center"}
                sx={{ marginBottom: "12px",display: 'flex' }}
              >
                <Chip
                  style={{ boxShadow: "3px 3px 5px #efefef" }}
                  icon={<AddShoppingCartOutlinedIcon color="#efefee" />}
                  variant="outlined"
                  sx={{
                    color: "#efefee",
                    padding: "auto",
                    cursor: "pointer",
                    height: "40px",
                    width: "220px",
                  }}
                  label="Add to Cart "
                  onClick={
                    handlePlan
                      ? () => {
                          navigate("/corporate");
                        }
                      : AddCart
                  }
                />
              </Box>
            )
          )}
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ marginBottom: "12px" }}
          >
            <Chip
              style={{ boxShadow: "3px 3px 5px #efefef" }}
              icon={
                handlePlan ? (
                  <SummarizeOutlinedIcon color="#efefee" />
                ) : (
                  <CreditScoreOutlinedIcon color="#efefee" />
                )
              }
              variant="outlined"
              sx={{
                color: "#efefee",
                padding: "auto",
                cursor: "pointer",
                height: "40px",
                width: "220px",
              }}
              label={handlePlan ? "Proceed Wth This Plan" : "Choose This Plan "}
              onClick={
                handlePlan
                  ? () => {
                      navigate("/corporate");
                    }
                  : paymentHandler
              }
            />
          </Box>
            {handlePlan && <Box sx={{ marginBottom: "12px",display: 'flex' }}><Chip sx={{height: "40px",}} variant="contained"/></Box>}
        </CardContent>
      </Box>
    </Card>
  );
};

export default PlanCard;

// Monthly plan text:
// - Text support for react error Unlimited
// - Suport for additional feautures in react application- 5

// Annual plan text :
// - same as above but additional features count 10

// Team plan text:
//  - same as monthly but additional feature count will be 12
