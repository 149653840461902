import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
// import { UsbRounded } from '@mui/icons-material';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { useMediaQuery } from '@mui/material';
import "./Query.css"
export default function CustomizedTimeline({name,icon,time}) {
  const isSmallScreen13 = useMediaQuery('(max-width:1350px)');
  return (
    <Timeline sx={{margin:'0px',display:'flex',justifyContent:'flex-start',marginY:'0px'}}  paddingX={'0'}>
      
      <TimelineItem  paddingX={'0'}>
        <TimelineOppositeContent paddingX={'0'}
          color="text.secondary"
        >
          
        </TimelineOppositeContent>
        <TimelineSeparator  paddingX={'0'} sx={{margin:'0',height:!icon?(isSmallScreen13?'120px':'100px'):'30px', maxHeight:icon&&'30px',marginY:'0px'}}>
          <TimelineConnector />
          {!icon&&<TimelineDot  paddingX={'0'} sx={{color:'gray',backgroundColor:'#eaeef2'}}>
            <SellOutlinedIcon sx={{height:'18px',width:'18px'}}/>
          </TimelineDot>}
          <TimelineConnector />
        </TimelineSeparator>
        {/* <TimelineContent >
          {!icon&&<Typography marginTop={isSmallScreen13?'30px':'34px'} display={'flex'} fontSize={'12px'}> <Typography style={{color:'blue',marginRight:'4px'}} display={'flex'} fontSize={'12px'}></Typography>{name?name:'Unknown '} added this {time}</Typography>}
        </TimelineContent> */}
      </TimelineItem>
    </Timeline>
  );
}