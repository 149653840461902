import React from 'react';
import { Typography, Container } from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../../Footer';

const containerStyle = {
  paddingTop: '16px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '66vh',
};

const sectionStyle = {
  marginBottom: '24px',
};

const paragraphStyle = {
  marginBottom: '8px',
  display:'flex',
  justifyContent:'center'
};

const CancellationRefundPolicy = () => {
  return (
    <>
    <Navbar/>
    <Container maxWidth="md" style={containerStyle}>
      <section style={sectionStyle}>
        <Typography variant="h4" style={{ marginBottom: '16px' }}>Cancellation & Refund Policy</Typography>
        <Typography variant="body1" style={paragraphStyle}>Last updated on Feb 27th 2024</Typography>
      </section>
      <section style={sectionStyle}>
        <Typography fontWeight={'bold'} fontSize={'24px'} variant="body1" style={paragraphStyle}>
          No cancellations & refunds are entertained.
        </Typography>
      </section>
    </Container>
    <Footer/>
    </>
  );
}

export default CancellationRefundPolicy;
