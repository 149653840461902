import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { Box, Button, Chip, Divider, Link, useMediaQuery } from "@mui/material";
import Navbar from "../Navbar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Query.css";
import { ArrowForward, EmojiEmotionsOutlined, Image } from "@mui/icons-material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import Comments from "./Comments";
import Footer from "../../Footer";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CustomizedTimeline from "./Timeline";
import AdjustSharpIcon from '@mui/icons-material/AdjustSharp';
import { Appstate } from "../../../../App";
import { FiImage } from "react-icons/fi";
import { AiOutlineLeft } from "react-icons/ai";
export default function QueryView() {
  const [issue, setIssue] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = React.useState([]);
  const [time ,setTime] = React.useState('');
  const {queryNum} = React.useContext(Appstate);
  const { queryId } = useParams();

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setComment(data);
  };

  function formatTime(time){
    const formattedTime = formatDistanceToNow(new Date(time), { addSuffix: true });
    return formattedTime;
  }

  async function showIssue(){
    try{
      const url = `${process.env.REACT_APP_BASE_URL}issue/${queryId}`
      const {data} = await axios.get(url);
      setIssue(data);
      getComments();
      setTime(formatTime(data.createdAt));
    } catch(e){
      console.error("Error fetching issue: ", e);
    }
  }

  React.useEffect(() => {
    showIssue();
  }, []);

  const getComments = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}issue/${queryId}/comments`;
      const {data} = await axios.get(url);
      setComments(data);
    } catch(e) {
      console.error("Error fetching comments: ", e);
    }
  }

  async function AddComment() {
    try {
      const authType = localStorage.getItem("authType");
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("isloggedIn");
      const url = `${process.env.REACT_APP_BASE_URL}issue/${queryId}/comments`;
      const config = authType !== "google" ? { headers: { Authorization: `Bearer ${token}` } } : {};
      const {data} = await axios.post(url, { text: comment, authType, userId }, config);
      setComment("");
      callComments();
    } catch(e) {
      console.error("Error adding comment: ", e);
    }
  }

  function callComments() {
    getComments();
  }

  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const isSmallScreen13 = useMediaQuery('(max-width:1350px)');
  const isSmallScreen10 = useMediaQuery('(max-width:1000px)');
  const name = issue&&(issue.name.charAt(0).toUpperCase() + issue.name.slice(1).toLowerCase());
  return (
    <>
      <Navbar />
      <Box>
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: 'white',
            overflowX: 'hidden',
          }}
        >
          <Box display={'flex'} width={isSmallScreen13?'95%':'80%'} margin={'auto'}  flexDirection={isSmallScreen?'column-reverse':'row'} justifyContent={'space-between'} alignItems={!isSmallScreen&&'center'}>
            <h1 style={{ display: 'flex', alignItems: 'center',fontWeight:'normal' }}> {issue && issue.title} <span style={{color:'grey',marginLeft:'10px',marginTop:'2px'}}> { "  #"+queryNum}</span>
              <span style={{ fontSize: '28px', color: 'gray', marginLeft: '4px', fontWeight: 'normal', marginTop: '4px' }}></span>
            </h1>
            {/* <Button variant="contained" sx={{
              backgroundColor: 'green',
              color: 'white',
              height: '2rem',
              width:'auto',
              textTransform: 'none',
              fontSize: '11px',
              paddingX:'4px',
              paddingY:'2px',
              borderRadius:'4px',
              "&:hover": {
                backgroundColor: "green",
                color: "white",
              },
              alignSelf:'flex-start',
              mt: !isSmallScreen&&isSmallScreen13?'45px':'28px'
            }}>New issue</Button> */}
          </Box>
          <Box display={'flex'} width={isSmallScreen13?'95%':'80%'} margin={'auto'} justifyContent={'flex-start'}>
            {/* <Chip icon={<AdjustSharpIcon sx={{ fontWeight: 'normal' }} color={'white'} />} label="Open" variant="contained" sx={{
              backgroundColor: 'green',
              color: 'white',
              width: '5.5rem',
              fontWeight: 'bold',
              height: '2.1rem',
              textTransform: 'none'
            }}/> */}
            <Typography display={'flex'} alignItems={'center'}  sx={{fontWeight:'normal',color:'gray',fontSize:'14px'}}><b style={{marginRight:'4px'}}>{name} </b>{" "} opened this issue {time} . {issue && issue.comments.length>0 ? issue.comments.length :"No " } comment{issue && issue.comments.length>1 && "s"}</Typography>
          </Box>
          <Divider sx={{ height: '0.3', color: 'black', backgroundColor: '#d0d7de', mb: '18px', width: isSmallScreen13?'95%':'80%', margin: '30px auto' }}/>
          <Box display={'flex'} flexWrap={'wrap'}>
          <Box display={'flex'} flexDirection={'column'} marginRight={0} width={isSmallScreen ? '100%' : '65%'}>
            <Box display={'flex'} marginX={'0px'} sx={{marginLeft:!isSmallScreen13&&'7%'}}>
              {!isSmallScreen &&
              <>
                <Avatar style={{ marginTop: '0px', marginLeft: !isSmallScreen13?'5vw':'2vw',marginTop:'2px' ,width:'40px',height:'40px'}}  aria-label="recipe"/>
                <Box display={'flex'} flexDirection={'column'} marginTop={'15px'} marginRight={'-2.2px'}>
                <Divider sx={{ width: '11px', height: '0.2px', backgroundColor: '#d0d7de', marginX: '0',transform:'rotate(135deg)' }}/>
                <Divider sx={{ width: '11px', height: '0.2px', backgroundColor: '#d0d7de',marginTop:'6px', marginX: '0',transform:'rotate(45deg)' }}/>
                </Box>
              </>
              }
              <Card sx={{border: '0.3px solid #d0d7de',color:'#1f2328',boxShadow:'none', minWidth: isSmallScreen? '94%':"81%",maxWidth:isSmallScreen? '85%':"90%", margin: isSmallScreen ? "0px auto" : '0px 0px', marginRight: isSmallScreen ? '' : '5vw',borderRadius:'6px' }}>
                <Box display={"flex"} sx={{borderBottom: '0.3px solid #d0d7de'}}>
                  <CardHeader
                    sx={{ backgroundColor: "#f6f8fa",fontFamily:'Seuge Ui', display:'flex',borderLeft:'none',borderLeftStyle:'outset',alignItems:'center', borderLeft: '0px', color: "black", height: "2px", width: '100%' }}
                    title={issue && (issue.name !== undefined ?
                      (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '13px',color:'grey',fontWeight:'lighter' }}>
                        <b style={{  marginRight: '4px',color:'black'}}>{name}</b>{" Commented " + time}
                        </span>
                        {/* <p style={{fontWeight:'lighter',color:'gray'}}>{<MoreHorizSharpIcon fontSize="10"/>}</p> */}
                      </div>) : "Unknown")}
                  />
                </Box>
                <Divider/>
                <CardContent sx={{ backgroundColor: 'white',padding:'16px',overflow:'visible', }}>
                  {issue && <>
                    {/* <h1>{issue.title}</h1> */}
                    <Typography variant="body2" color={'text.primary'} fontWeight={'lighter'}  dangerouslySetInnerHTML={{ __html: issue.description }}/>
                  </>}
                  <EmojiEmotionsOutlined  style={{borderRadius:'100%',height:'20px',width:'20px', backgroundColor:'#f6f8fa',color:'gray',padding:'2px',border:'0.2px solid #d0d7de'}}/>
                </CardContent>
              </Card>
            </Box>
            <Box style={{ width: "100%", margin: "0px auto", height: "100%" }}>
              {comments && comments.map((c,i) => {
                return <Comments comment={c}/>
              })}
               <Box sx={{ margin: '0 0',display:'flex',justifyContent:'flex-start', alignItems: 'center' }}>
                <Box sx={{ marginLeft: isSmallScreen ? '' : isSmallScreen13 ? '10%' : '18%' }}>
                  {/* Your content goes here */}
                <Divider   sx={{backgroundColor:'black',height:'0.3px',width:'20px',margin:'10px',marginLeft:isSmallScreen?'17vw':'2vw',transform:'rotate(90deg)'}} />
                </Box>
              </Box>
              <Box sx={{marginTop:'0px' }}>
                <Divider sx={{ height: '0.1px',display:'block', color: 'black', backgroundColor: 'grey' ,marginLeft: isSmallScreen13?'3%':'15%', mb: '18px',width:isSmallScreen13?'94%':'82%'  }}/>
                <Box display={'flex'} alignItems={'center'}sx={{marginBottom: "10px" ,marginLeft: isSmallScreen13?'4%':'15%'}}>
                {!isSmallScreen&&<Avatar sx={{mr:'6px'}}/>}
                <Typography sx={{ display: "flex", alignItems: "center",fontWeight:'550' }}>
                  Add a Comment
                  {/* <ArrowForward sx={{ height: "18px", width: "20px" }}/> */}
                </Typography>
                </Box>
              </Box>
              <Box sx={{ backgroundColor: "white", width: isSmallScreen?'93%':isSmallScreen13?'81%':'75%',borderRadius:'8px',border:'0.3px solid #d0d7de',margin:'auto',marginLeft:isSmallScreen?'auto':(isSmallScreen10?'14%':isSmallScreen13?'13%':'19%') }}>
                <CKEditor editor={ClassicEditor} data={comment} onChange={handleEditorChange}/>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center",marginLeft:'8px',marginRight:'8px',marginTop:'-7px',marginBottom:'4px' }}>
                  <input id="upload-file" type="file" style={{ display: "none" }}/>
                  {/* <Divider sx={{height:'0.2px',display:'flex',alignItems:'center',width:'25px',backgroundColor:'grey',transform:'rotate(90deg)'}}/> */}
                  <input id="upload-file" type="file" style={{ display: "none" }}/>
                  <label htmlFor="upload-file" >
                    <Button variant="text" component="span" sx={{alignSelf:'self-end',"&:hover": {
                        backgroundColor: "#f6f8fa",
                        color: "black",
                      },}}
                            style={{ color: "black", textTransform: "none", }}>
                      <FiImage color={"grey"} fontSize="20px"/> <Typography sx={{fontSize:isSmallScreen13?'12px':'12px',marginLeft:'4px',fontWeight:'550',color:'#24292f'}}>Paste, drop or click to add files</Typography>
                    </Button>
                  </label>
                </div>
              </Box>
              <Box sx={{ display: "flex", marginY: "12px", justifyContent: "center", width:isSmallScreen13?'160%':'180%' }}>
                <Button sx={{
                  backgroundColor: "green", color: "white", "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                  },
                }} variant="contained" onClick={AddComment} style={{textTransform:'none'}} disabled={comment.length > 0 ? false : true}>
                  Comment
                </Button>
              </Box>
            </Box>
          </Box>
            </Box>
        </Box>
      </Box>
      <Divider sx={{ height: '0.1px', color: 'black', backgroundColor: 'grey' ,mb: '18px',width:isSmallScreen13?'100%':'100%'  }}/>
      <Footer/>
    </>
  );
}
