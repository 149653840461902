import React from 'react';
import { Typography, Container } from '@mui/material';
import Footer from '../../Footer';
import Navbar from '../Navbar';

const containerStyle = {
  paddingTop: '16px',
  paddingBottom: '16px',
  textAlign:'justify'
};

const sectionStyle = {
  marginBottom: '24px',
};

const paragraphStyle = {
  marginBottom: '8px',
};

const TermsAndConditions = () => {
  return (
    <>
    <Navbar/>
    <Container maxWidth="md" style={containerStyle}>
      <section style={sectionStyle}>
        <Typography sx={{display:'flex',justifyContent:'center'}} variant="h4" style={{ marginBottom: '16px' }}>Terms & Conditions</Typography>
        <Typography sx={{display:'flex',justifyContent:'center'}} variant="body1" style={paragraphStyle}>Last updated on Feb 27th 2024</Typography>
      </section>
      <section style={sectionStyle}>
        <Typography variant="body1" style={paragraphStyle}>
          The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern USHA KIRAN RAJ BRUNDHAVANAM's relationship with you in relation to this website.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          The term 'USHA KIRAN RAJ BRUNDHAVANAM' or 'us' or 'we' refers to the owner of the website whose registered/operational office is TYPE 2 208 CRPF CAMPUS BARKAS K V Rangareddy TELANGANA 500005. The term 'you' refers to the user or viewer of our website.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          The use of this website is subject to the following terms of use:
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          The content of the pages of this website is for your general information and use only. It is subject to change without notice.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          You may not create a link to this website from another website or document without USHA KIRAN RAJ BRUNDHAVANAM’s prior written consent.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time
        </Typography>
        <Typography variant="body1" style={paragraphStyle}>
          <b>Disclaimer:</b> The above content is created at USHA KIRAN RAJ BRUNDHAVANAM's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.
        </Typography>
      </section>
    </Container>
    <Footer/>
    </>
  );
}

export default TermsAndConditions;
