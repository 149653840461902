import React from 'react';
import { Grid, Box } from '@mui/material';
import PlanCard from './PlanCard';
import Navbar from './sections/Navbar';
import Footer from './Footer';

export default function Plans() {
  return (
    <>
    <Navbar/>
    <Box sx={{ padding: '24px 30px'}}>
      <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <PlanCard style={{ backgroundColor: 'red' }}  name={'Annually'} count={5} amount={11999} disc={15}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <PlanCard style={{ backgroundColor: 'blue' }} name={'Monthly'} count={10} amount={1500} disc={15}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <PlanCard style={{ backgroundColor: 'green' }} name={'Team'} count={12} amount={18000} disc={15}/>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <PlanCard style={{ backgroundColor: 'green' }} name={'Corporate'} count={12} amount={18000} disc={15} first={"Large no of users Supported"} second={"Everything Unlimited, can book a demo also"} handlePlan={true}/>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </>
  );
}
// Large no of user
// All unlimited 
// Book a demo
// Annual, month and team plan, corporate plan

// 11999rs+gst discount add some money and show cross fonally to these amounts 

// 1500rs+ gst
// 18000rs+gst
// Redirect to demo registration