import React, { useState } from "react";
import { Box, Button,  Typography, useMediaQuery } from "@mui/material";
import {
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
export default function Register() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const issmallscreen6 = useMediaQuery("(max-width:500px)");
  const issmallscreen7 = useMediaQuery("(max-width:750px)");
  const issmallscreen9 = useMediaQuery("(max-width:900px)");
  const issmallscreen11 = useMediaQuery("(max-width:1100px)");
  const issmallscreen14 = useMediaQuery("(max-width:1400px)");
  const handleRegister = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/register`,
        {
          name: values.name,
          email: values.email,
          password: values.password,
        }
      );
      if (!response.status === 200) {
        toast.error(response.data.message);
        return;
      }
      // Handle successful registration, e.g., show success message, redirect user, etc.
      toast.success(`Registered Successfully as ${values.name}`, {
        style: { backgroundColor: "green", color: "white" },
        position: "bottom-left",
      });
      navigate("/login"); // Redirect user to login page after successful registration
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "91.5vh",
        backgroundColor:'#efefef'
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          width: issmallscreen6
            ? "95%"
            : issmallscreen7
            ? "70%"
            : issmallscreen9
            ? "40%"
            : issmallscreen11
            ? "30%"
            : issmallscreen14
            ? "30%"
            : "22%",
          minHeight: "70%",
          display: "flex",
          borderRadius: "15px",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto 16px",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
            }}
            fontWeight={"bold"}
            fontSize={"26px"}
            fontFamily={"sans-serif"}
          >
            Create an Account and Join us!
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ margin: "6px 0px", marginTop: "14px" }}
          >
            <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-name"
              sx={{ height: "50px", backgroundColor: "#efefef" }}
              value={values.name}
              onChange={handleChange("name")}
              label="Name"
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ margin: "6px 0px", marginTop: "14px" }}
          >
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              sx={{ height: "50px", backgroundColor: "#efefef" }}
              value={values.email}
              onChange={handleChange("email")}
              label="Email"
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ margin: "6px 0px", marginBottom: "10px" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              sx={{
                height: "50px",
                backgroundColor: "#efefef",
                marginBottom: "8px",
              }}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              height: "40px",
              width: "100%",
              borderRadius: "10px",
              marginBottom: "8px",
              backgroundColor: "#1E232C",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            onClick={handleRegister}
          >
            <Typography>Register</Typography>
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>

        <Box>
          <Typography
            fontSize={"11px"}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px",
              marginTop: "80px",
            }}
          >
            Already have an Account?{" "}
            <Typography
              fontSize={"11px"}
              color={"blue"}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Login Now
            </Typography>{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
