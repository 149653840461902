import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Avatar } from '@mui/material';

const Success = () => {
  return (
    <Avatar sx={{ backgroundColor: 'green', color: 'white',width:'100px',height:'100px' }}>
        <DoneIcon style={{ color: 'white', fontSize: 71 }} />
    </Avatar>
  );
};

export default Success;
