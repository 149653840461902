import React, { createContext, useEffect, useState, Suspense } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./feature/Dashboard/Dashboard";
import QueryView from "./feature/Dashboard/sections/Pages/QueryViewPags";
import Login from "./feature/Dashboard/Login";
import Plans from "./feature/Dashboard/Plans";
import PaySuccess from "./feature/Dashboard/PaySuccess";
import CorporatePlan from "./feature/Dashboard/sections/Pages/CorporatePlan";
import CartPage from "./feature/Dashboard/sections/CartPage";
import TermsAndConditions from "./feature/Dashboard/sections/Pages/TermsAndConditions";
import CancellationRefundPolicy from "./feature/Dashboard/sections/Pages/Cancellation";
import PrivacyPolicy from "./feature/Dashboard/sections/Pages/PrivacryPolicy";
import Shipping from "./feature/Dashboard/sections/Pages/Shipping";
import ContactUs from "./feature/Dashboard/sections/Pages/ContactUs";
import { Toaster } from "react-hot-toast";
import Register from "./feature/Dashboard/Register";
import AfterGoogleLogin from "./feature/Dashboard/sections/Pages/AfterGoogleLogin";
import UserScreen from "./feature/Users/UserScreen";
import "./App.css";

const Appstate = createContext();

const App = () => {
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);
  const [login, setLogin] = useState(localStorage.getItem("isloggedIn"));
  const [queryNum, setQueryNum] = useState(1);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(
      location.pathname + location.search,
      null,
      location.pathname
    );
  }, [location]);
  return (
    <Appstate.Provider
      value={{
        cart,
        setCart,
        user,
        setUser,
        login,
        setLogin,
        queryNum,
        setQueryNum,
      }}
    >
      <Toaster />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/queries/:queryId" element={<QueryView user={user} />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />
          <Route
            path="/cancellation&refund"
            element={<CancellationRefundPolicy />}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/users" element={<UserScreen />} />
          <Route
            path="/login"
            element={
              <Login login={login} setLogin={setLogin} setUser={setUser} />
            }
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/auth/:id"
            element={
              <AfterGoogleLogin
                setLogin={setLogin}
                user={user}
                setUser={setUser}
              />
            }
          />
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
          {login && <Route path="/plans" element={<Plans />} />}
          {login && <Route path="/carts" element={<CartPage />} />}
          {login && <Route path="/paysuccess" element={<PaySuccess />} />}
          {login && <Route path="/corporate" element={<CorporatePlan />} />}
        </Routes>
      </Suspense>
    </Appstate.Provider>
  );
};
export { Appstate };
export default App;
