import Navbar from "../Dashboard/sections/Navbar";
import {
  Container,
  IconButton,
  Box,
  Pagination,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../Dashboard/Footer";
import axios from "axios";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d38ef2",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserScreen = () => {
  const [totalCount, setTotalCount] = React.useState(0);
  const [totaluserCount, settotaluserCount] = React.useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editedUser, setEditedUser] = useState(false);
  const [editedUserId, setEditedUserId] = useState("");

  const userId = localStorage.getItem("isloggedIn");
  const userDataString = localStorage.getItem("userData");

  const userDataObject = JSON.parse(userDataString);

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}users/admin/users?page=${currentPage}`;

      // Attach JWT token to every request using Axios interceptors
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${userId}`;
        return config;
      });

      const response = await axios.get(url);
      setCurrentPage(response.data.currentPage);
      setUsers(response.data.users);
      setTotalCount(response.data.totalPages);
      settotaluserCount(response.data.totalUserCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId, currentPage]);

  const handleDeleteUser = async () => {
    try {
      // Prepare the URL for the delete request
      const url = `${process.env.REACT_APP_BASE_URL}users/delete/${selectedUser._id}`;

      // Make a DELETE request to the API endpoint
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      });

      // Check if the request was successful
      if (response.status === 200) {
        toast.success(response.data.message, {
          style: { backgroundColor: "green", color: "white" },
          position: "bottom-left",
        });
        // Close the modal after deletion
        setIsModalOpen(false);
        fetchData();
      } else {
        toast.success(response.data.message, {
          style: { backgroundColor: "red", color: "white" },
          position: "bottom-left",
        });
      }
    } catch (error) {
      toast.success("Something went wrong.", {
        style: { backgroundColor: "red", color: "white" },
        position: "bottom-left",
      });
    }
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };
  const handleCloseAddModal = () => {
    setName("");
    setEmail("");
    setPassword("");
    setRole("");
    setEditedUser(false);
    setIsAddModal(false);
  };

  const handleAddUser = async () => {
    try {
      // Prepare the user object with the details
      const userPayload = {
        name: name,
        email: email,
        role: role,
      };

      let url;
      let method;

      if (editedUser) {
        // If editedUser exists, it means we are editing an existing user
        url = `${process.env.REACT_APP_BASE_URL}users/admin/users/${editedUserId}`;
        method = "PUT";
      } else {
        // If editedUser does not exist, it means we are adding a new user
        url = `${process.env.REACT_APP_BASE_URL}users/add`;
        method = "POST";
        userPayload.password = password;
      }

      // Make a POST request to the API endpoint
      const response = await axios({
        method: method,
        url: url,
        data: userPayload,
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      });

      // Check if the request was successful
      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.message, {
          style: { backgroundColor: "green", color: "white" },
          position: "bottom-left",
        });
        setIsModalOpen(false);

        // Clear input fields
        setName("");
        setEmail("");
        setPassword("");
        setRole("");
        setIsAddModal(false);
        fetchData();
      } else {
        toast.success(response.data.message, {
          style: { backgroundColor: "red", color: "white" },
          position: "bottom-left",
        });
      }
    } catch (error) {
      toast.success("Something went wrong.", {
        style: { backgroundColor: "red", color: "white" },
        position: "bottom-left",
      });
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleEditUser = (user) => {
    console.log(user);
    setIsAddModal(true);
    setEditedUser(true);
    setName(user.name);
    setEmail(user.email);
    setRole(user.role);
    setEditedUserId(user._id);
  };
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h5" gutterBottom>
          Total Users: {totaluserCount}
        </Typography>
        {userDataObject.role === "admin" && (
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <IconButton
              color="primary"
              aria-label="add user"
              onClick={() => setIsAddModal(true)}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Subscribed</StyledTableCell>
                {userDataObject.role === "admin" && (
                  <StyledTableCell>Action</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map((user) => (
                  <StyledTableRow key={user._id}>
                    <StyledTableCell>{user.name}</StyledTableCell>
                    <StyledTableCell>{user.email}</StyledTableCell>
                    <StyledTableCell>{user.role}</StyledTableCell>
                    <StyledTableCell>
                      {user.isSubscribed ? "Yes" : "No"}
                    </StyledTableCell>
                    {userDataObject.role === "admin" && (
                      <StyledTableCell>
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditUser(user)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          onClick={() => handleOpenModal(user)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={totalCount}
            color="secondary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Container>
      <Footer />
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          fullWidth
          onClose={() => handleCloseModal()}
          PaperProps={{ sx: { borderRadius: "10px" } }}
        >
          <DialogTitle sx={{ fontSize: "25px", textAlign: "center" }}>
            Delete User
          </DialogTitle>
          <DialogContent>
            <Typography textAlign="center">
              Are you sure you want to delete&nbsp;
              <Typography component="span" fontWeight={600}>
                {selectedUser.name}
              </Typography>
              &nbsp;user?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ alignSelf: "center", padding: "0 20px 20px" }}>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                height: "40px",
                width: "100%",
                borderRadius: "10px",
                marginBottom: "8px",
                backgroundColor: "#1E232C",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={() => handleCloseModal()}
            >
              <Typography>Cancel</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                height: "40px",
                width: "100%",
                borderRadius: "10px",
                marginBottom: "8px",
                backgroundColor: "#d38ef2",
                color: "white",
                "&:hover": {
                  backgroundColor: "#d38ef2",
                  color: "white",
                },
              }}
              onClick={() => handleDeleteUser()}
            >
              <Typography>Confirm</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isAddModal && (
        <Dialog
          open={isAddModal}
          onClose={handleCloseAddModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{editedUser ? "Edit User" : "Add User"}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!editedUser && (
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              select
              margin="dense"
              id="role"
              label="Role"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="subAdmin">Sub Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                marginBottom: "8px",
                backgroundColor: "#1E232C",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={handleCloseAddModal}
            >
              <Typography> Cancel</Typography>
            </Button>
            <Button
              onClick={handleAddUser}
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "10px",
                marginBottom: "8px",
                backgroundColor: "#d38ef2",
                color: "white",
                "&:hover": {
                  backgroundColor: "#d38ef2",
                  color: "white",
                },
              }}
            >
              <Typography>{editedUser ? "Update" : "Add"}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default UserScreen;
